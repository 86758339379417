.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card{
  border: 0.5px solid rgb(243, 149, 149);
  display: block;
  text-align: center;
  
}

img{
  height: 300px;
  width: 300px;
}

*{
  box-sizing: border-box;
  margin: 0;
}

html, body{
  margin: 0;
  font-family: 'Montserrat', helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.movie_card{
  position: relative;
  display: block;
  width: 800px;
  height: 350px;
  margin: 100px auto; 
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s;
}
.movie_card:hover{
    transform: scale(1.02);
    transition: all 0.4s;
  }
.info_section{
    position: relative;
    width: 100%;
    height: 100%;
	  background-blend-mode: multiply;
    z-index: 2;
    border-radius: 10px;}
.movie_header{
      position: relative;
      padding: 25px;
      height: 40%;}
h1{
        color: rgb(10, 10, 10);
        font-weight: 400;
      }
h4{
        color: #0d58ad;
        font-weight: 400;
      }
      .minutes{
        display: inline-block;
        margin-top: 10px;
        color: rgb(201, 32, 32);
        padding: 5px;
        border-radius: 5px;
        border: 1px solid rgba(255,255,255,0.13);
      }
      .type{
        display: inline-block;
        color: #3466a0;
        margin-left: 10px;
      }
      .locandina{
        position: relative;
        float: left;
        margin-right: 20px;
        height: 120px;
        box-shadow: 0 0 20px -10px rgba(0,0,0,0.5);
      }
    .movie_desc{
      padding: 25px;
      height: 50%;
      color: #085edf;
    }
    .movie_social{
      height: 10%;
      padding-left: 15px;
      padding-bottom: 20px;}
      ul{
        list-style: none;
        padding: 0;}
        li{
          display: inline-block;
          color: rgba(216, 12, 12, 0.815);
          transition: color 0.3s;
          transition-delay: 0.15s;
          margin: 0 10px;}
          li:hover{
            transition: color 0.3s;
            color: rgba(78, 16, 16, 0.8);
          }
          i{
            font-size: 19px;
            cursor: pointer;
          }
  .blur_back{
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%; right: 0;
    background-size: cover;
    border-radius: 11px;
  }  
  .blur_back{
    width: 100%;
    background-position: 50% 50% !important;  
  }
  
  .movie_header{
    width: 100%;
    margin-top: 85px;
  }
  
  .movie_desc{
    width: 100%;
  }
  
  .info_section{
    background: linear-gradient(to top, rgb(20, 20, 19) 50%, transparent 100%);
    display: inline-grid;
  }